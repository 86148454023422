// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-floading-js": () => import("./../../../src/pages/floading.js" /* webpackChunkName: "component---src-pages-floading-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-ticket-js": () => import("./../../../src/pages/ticket.js" /* webpackChunkName: "component---src-pages-ticket-js" */),
  "component---src-pages-zero-js": () => import("./../../../src/pages/zero.js" /* webpackChunkName: "component---src-pages-zero-js" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/Destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-question-js": () => import("./../../../src/templates/Question.js" /* webpackChunkName: "component---src-templates-question-js" */)
}

